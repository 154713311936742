import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import Hero from "./../components/hero"
import ThankYou from "./screens/ucc2023/thank-you"


const ThankYouPage = ({ location }) => {
   <Hero pageTitle="Success" />
   return (
    <>
      <Hero pageTitle="Success" />
      {(!location.state)
        ?
        <ThankYou />
        :
        <ThankYou
          first_name={location.state.first_name}
        />
      }
    </>
  )
}
export default ThankYouPage

export const Head = () => (
  <Seo
  title="Stay in Touch"
  description="Thank you for visiting Visby. Let’s stay in touch."
/>
)
